
$(document).ready(function () {

    var current = $('.head-nav__link--current');


    $(document).on('mouseenter', '.head-nav__links', function () {
        current.removeClass('head-nav__link--current');
    }).on('mouseleave', '.head-nav__links', function () {
        current.addClass('head-nav__link--current');
    });


});